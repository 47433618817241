@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";

.overlay {
  position: fixed;
  inset: 0;
  background-color: $backgroundModal;
  backdrop-filter: blur(1.3rem);
}

.modal {
  position: fixed;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  height: auto;
  border-radius: 1.5rem;
}

.modalContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 1.5rem;
  background-color: $primaryColor;
  border-color: $primaryColor;
  text-decoration: none;
  outline: none;

  img {
    cursor: pointer;
  }
}

.contentCross {
  text-align: right;

  img {
    height: 4rem;
  }
}

@media (max-width: 1366px) {
  .modal {
    width: 40rem;
  }
}

@media (max-width: 700px) {
  .modal {
    width: 34rem;
  }

  .contentCross {
    img {
      height: 4rem;
    }
  }
}

@media (max-width: 540px) {
  .modal {
    width: 20rem;
  }

  .contentCross {
    img {
      height: 2.8rem;
    }
  }
}
