@import "../../css/colors.scss";

.printYourPets {
  text-align: left;
  padding-top: 5rem;
  padding-bottom: 5rem;
  max-width: 90rem;
  background-color: $secondBackgroundColor;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;

  > div {
    display: flex;
    background-image: url("./../../assets/images/home/background-pets.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    border-radius: 6rem;
    background-color: $primaryColor;
  }

  .content {
    flex: 0 0 30%;
    font-weight: 700;
    color: $primaryColor;
    padding-left: 6rem;
    padding-top: 5rem;
    padding-bottom: 3rem;

    p {
      &:first-of-type {
        margin-top: 0;
        margin-bottom: 6rem;
      }

      text-align: left;
      font-size: 2.1rem;
      line-height: 2.6rem;
      font-weight: 700;
    }
  }

  .pets {
    flex: 1 1 70%;
    text-align: center;
  }

  img {
    max-height: 37rem;
    max-width: 100%;
    margin: 0 0 0 10rem;
  }
}


@media (max-width: 1250px) {
  .printYourPets {
    width: 100%;

    > div {
      margin: 0 auto;

      max-width: 32rem;
      background-image: url("./../../assets/images/home/background-pets-mobile.png");
      flex-direction: column-reverse;
      background-position: center bottom;
    }
 
    .pets {
      margin: 0 0 4rem;
    }
    .content {
      padding: 7rem 2rem 5rem 2rem;
      p {
        font-size: 1.4rem;

        &:first-of-type {
          margin-bottom: 3rem;
        }
      }
    }

    img {
      max-height: 34rem;
      margin: 0;
    }

  }
}

@media (max-width: 525px) {
  .printYourPets {
    > div {
      margin: 0 1rem;
      background-size: 30rem;
    }

    .content {
      padding: 2rem 5rem 1rem 5rem;
      p {
        font-size: 1.2rem;
      }
    }

    img {
      margin: 0 0 1rem;
    }

    .pets {
      margin: 0 0 3rem;
    }
  }
}
