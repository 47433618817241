@import "./css/colors";
@import "./css/fonts.scss";

@font-face {
  font-family: "CircularStd";
  src: url("./assets/fonts/CircularStd-Bold.ttf");
  src: url("./assets/fonts/CircularStd-Book.ttf");
}

@font-face {
  font-family: "Raregotchi";
  src: url("./assets//fonts/RaregotchiRegular.ttf");
}

html,
body {
  margin: 0;
  font-family: "CircularStd";
  background-color: $primaryColor;
  font-size: 12px;
}

@media (max-width: 800px) {
  body {
  }
}

*:focus {
  outline: none;
}
