.curtain {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ed165a;
  animation-name: growBackground;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 1001;
}

.bottom {
  margin-top: -340px;
  height: 100%;
  width: 100%;
  background-image: url("./../../assets/images/toyOpening/toy-opening-flip.gif");
  background-repeat: repeat-x;
}

@keyframes growBackground {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(135%);
  }
}
