@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";

.text {
  font-weight: 1000;
  font-style: normal;
  line-height: 2rem;
  font-size: 1.5rem;
  text-align: center;
  color: $secondaryTextColor;
  margin-block-start: 0;
}

.subtext {
  font-size: 1.1rem;
  line-height: 1.4rem;
  text-align: center;
  font-style: italic;
  font-weight: 450;
  color: $secondaryTextColor;
  margin-block-start: 0;
}

.contentValidation {
  text-align: center;
  height: 2rem;
}

.validation {
  font-size: 0.7rem;
  line-height: 0.9rem;
  text-align: center;
  font-style: italic;
  font-weight: 450;
  color: $secondaryFontColor;
}

input {
  background: $inputColor;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 50%;
  border: transparent;
  padding: 0 1rem;
}

.contentButton {
  text-align: center;
  margin: 3rem 0 1.5rem;

  img {
    height: 1.5rem;
    margin: 0 2rem 1rem;
  }
}

@media (max-width: 1366px) {
  .text {
    margin-block-start: 0;
    margin-block-end: 0.5rem;
  }

  .subtext {
    margin-block-start: 0;
  }

  .contentButton {
    margin: 2rem 0 1.5rem;
  }
}

@media (max-width: 800px) {
  .content {
    position: absolute;
    top: 20%;
    width: 100%;
    left: 0;
  }

  .text {
    font-size: 0.9rem;
    line-height: 1.1rem;
    margin-block-end: 1em;
  }
  
  .subtext {
    font-size: 0.75rem;
    line-height: 0.9rem;
    margin-block-start: 1em;
    margin-inline: 4em;
  }

  .validation {
    font-size: 0.6rem;
    line-height: 0.7rem;
  }

  input {
    width: 75%;
  }

  .contentButton {
    margin: 4rem 0 0;

    img {
      height: 0.8rem;
      margin: 0 0.5rem 1rem;
    }
  }
}
