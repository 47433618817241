@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.petCard {
  position: relative;
  z-index: 10;
  max-width: 23.75rem;
  border-radius: 3.1rem;
  text-align: center;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 18rem;

  .innerBox {
    margin-top: -7rem;
    box-shadow: 0rem 3rem 2.2rem -1rem $effectBlurColor;
    border-radius: 3.1rem;
    height: 20rem;
    padding: 13rem 1.5rem 0 1.5rem;
    background-color: $primaryColor;
  }

  .imagePets {
    max-height: 22.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -135%);
  }

  .imageButton {
    max-height: 5.18rem;
  }

  .contentText {
    display: flex;
    align-items: center;
    height: 15rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $primarySubTextColor;
    margin-block-start: 0;
  }
}

@media (max-width: 1250px) {
  .petCard {
    max-width: 15.5rem;
    margin-top: 15rem;

    .innerBox {
      height: 15rem;
      padding: 7rem 0.5rem 0 0.5rem;
    }

    .imagePets {
      max-height: 14.5rem;
      transform: translate(-50%, -155%);
    }

    .imageButton {
      max-height: 3.5rem;
    }

    .contentText {
      height: 10rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
}

@media (max-width: 800px) {
  .petCard {
    margin: 23rem 1rem 3rem 1rem;
    max-width: 22.75rem;

    .innerBox {
      height: 13rem;
    }

    .imagePets {
      max-height: 22.7rem;
      transform: translate(-50%, -130%);
    }

    .contentText {
      height: 7rem;
    }

    img {
      max-height: 4rem;
    }
  }
}
