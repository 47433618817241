.headerHome {
  margin-top: -1rem;
  width: 120%;
  max-width: 147rem;
}

.headerMobile {
  display: none;
}

@media (max-width: 1750px) {
  .headerHome {
    margin-top: 0;
  }
}

@media (max-width: 1366px) {
  .headerHome {
    width: 145%;
  }
}

@media (max-width: 710px) {
  .headerHome {
    display: none;
  }

  .headerMobile {
    display: block;
    max-width: 175%;
  }
}
