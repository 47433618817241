@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentPreSale {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 5.8rem 0 0 8.5rem;

  .contentTextPreSale {
    text-align: center;
    .textPreSale {
      text-align: center;
      font-family: $secondaryFont;
      font-size: 3.7rem;
      font-weight: bold;
      color: $secondaryTextColor;
      margin: 0;
    }
  }

  .contentImageToyPackages {
    display: flex;
    justify-content: center;
    .imageToyPackages {
      max-width: 75%;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
    }
  }
}

@media (max-width: 1750px) {
  .contentPreSale {
    padding: 1rem 2.3rem 2rem 5.9rem;
    gap: 0;

    .contentTextPreSale {
      .textPreSale {
        font-size: 2.7rem;
      }
    }

    .contentImageToyPackages {
      .imageToyPackages {
        width: 39rem;
      }
    }
  }
}

@media (max-width: 1250px) {
  .contentPreSale {
    .contentTextPreSale {
      .textPreSale {
        font-size: 2rem;
        line-height: 3.1rem;
        margin: 0;
      }
    }

    .contentImageToyPackages {
      width: 40rem;
    }
  }
}

@media (max-width: 800px) {
  .contentPreSale {
    flex-direction: column;
    gap: 1rem;
    padding-right: 5.9rem;
    .contentImageToyPackages {
      .imageToyPackages {
        width: 28rem;
      }
    }
  }
}

@media (max-width: 460px) {
  .contentPreSale {
    max-width: 90%;
  }
}
