@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";


.content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16rem;

  p {
    font-weight: 900;
    font-style: normal;
    line-height: 2.1rem;
    font-size: 1.5rem;
    text-align: center;
    color: $secondaryTextColor;
    margin-block-start: 0;
  }
  

  .contentButton {
    text-align: center;
  
    img {
      height: 1rem;
      margin: 0 2rem 1rem;
    }
  }
}

@media (max-width: 800px) {
  .content {
    
    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }

    .contentButton {
      margin: 1rem 0 0;

      img {
        height: 0.8rem;
      }
    }
  }
}
