@import "../../css/colors.scss";
@import "../../css/fonts.scss";
@import "../../css/flexbox.scss";

.faqContainer {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .questionsContainer {
    @include flexCenter(center);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 9rem;
    max-width: 90rem;
    width: 100%;
    
    .faqLogo {
      height: 5.6rem;
      margin-bottom: 3rem;
    }
    .faqLogoResponsive {
      display: none;
    }

    .questionsList {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 5rem;
    }
  }

  .italicFont {
    font-weight: 100;
    font-style: italic;
  }
}


@media (max-width: 1750px) {
  .faqContainer {
    .questionsContainer {
      max-width: 75rem;
    } 
  }
}

@media (max-width: 1460px) {
  .faqContainer {
    .questionsContainer {
      max-width: 65rem;
    }
  } 
}

@media (max-width: 1366px) {
  .faqContainer {
    .questionsContainer {
      max-width: 60rem;
      .questionsList {
        margin-bottom: 3.5rem;
      }
      .faqLogo {
        display: none;
      }
      .faqLogoResponsive {
        display: initial;
        height: 5rem;
        padding: 1rem 0rem 3rem 0rem;
      }
    }
  }
}

@media (max-width: 920px) {
  .faqContainer {
    .questionsContainer {
      max-width: 50rem;
    }
  }
} 

@media (max-width: 780px) {
  .faqContainer {
    .questionsContainer {
      max-width: 40rem;
      .faqLogoResponsive {
        height: 2.5rem;
      }
    }
  }
}

@media (max-width: 540px) {
  .faqContainer {
    .questionsContainer {
      max-width: 25rem;

      .questionsList {
        margin-bottom: 3rem;
      }
    }
  }
}
