@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.eventTitle {
  margin: 5rem 0 3rem;
  height: 5.6rem;
}
.toySale {
  height: 2.8rem;
}

.contentText {
  text-align: center;
  padding: 1.5rem 5rem 0.6rem 5rem;
  max-width: 50rem;

  .description {
    font-weight: 500;
    line-height: 2.3rem;
    font-size: 1.8rem;
    text-align: center;
    color: $primarySubTextColor;
    margin-bottom: 2.5rem;

    span {
      font-weight: 900;
      color: $primaryBackgroundColor;
    }
  }
}

@media (max-width: 1366px) {
  .eventTitle {
    height: 5rem;
  }

  .toySale {
    height: 2.5rem;
  }

  .contentText {
    max-width: 44rem;

    .description {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 2.5rem;
    }
  }
}

@media (max-width: 680px) {
  .eventTitle {
    width: 43rem;
  }

  .toySale {
    height: 2.2rem;
  }

  .contentText {
    width: 41.5rem;

    .description {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 540px) {
  .eventTitle {
    height: 2.5rem;
    margin: 5rem 0 1rem;
  }

  .toySale {
    padding-top: 1rem;
    height: 1.5rem;
  }

  .contentText {
    padding: 1rem 5rem;

    .description {
      margin: 1.5rem auto;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.3rem;
      max-width: 22.5rem;
    }
  }
}
