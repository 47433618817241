@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.overlay {
  position: fixed;
  inset: 0;
  background-color: $backgroundModal;
  backdrop-filter: blur(1.3rem);
  z-index: 1001;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: min(100vw - 50px, 100vh - 30px);
  width: min(100vw - 50px, 100vh - 250px);
}


.cross {
  height: 4rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
  cursor: pointer;
}

.content {
  text-align: center;
}

.imageToy {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
}

@media (max-width: 900px) {
  .modal {
    width: min(100vw - 50px, 100vh - 150px);
  }
}

@media (max-width: 540px) {
  .modal {
    height: 66%;
    width: 98%;
  }
}
