@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.overlay {
  position: fixed;
  inset: 0;
  background-color: $backgroundModal;
  backdrop-filter: blur(1.3rem);
}

.modal {
  position: fixed;
  top: 15%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  height: auto;
}

.modalContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 1.5rem;
  background-color: transparent;
  border-color: $primaryColor;
  text-align: center;
  outline: none;
  height: 58rem;

  img {
    cursor: pointer;
  }
}

.contentCross {
  text-align: right;

  img {
    height: 4rem;
  }
}

.content {
  text-align: center;
  padding: 0 0.5rem;
}

:not(:defined) > * {
  display: none;
}

model-viewer {
  background-color: transparent;
  overflow-x: hidden;
  --poster-color: $primaryColor;
  height: 55rem;
  width: auto;
  margin-top: -1rem;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

model-viewer[ar-tracking="not-tracking"] > #ar-failure {
  display: block;
}
