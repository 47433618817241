$primaryColor: #ffffff;
$primaryBackgroundColor: #000000;
$secondBackgroundColor: #fef7ed;
$secondaryColor: #ebebeb;
$secondaryFontColor: #ed165a;
$primaryTextColor: #717786;
$secondaryTextColor: #001845;
$thirdTextColor: #d2ac69;
$fourthTextColor: #009af7;
$primarySubTextColor: #939cb2;
$navigationLinkColor: #858688;
$inputColor: #e6e6e6;
$linkColor: #437eec;
$effectBlurColor: #939cb226;
$backgroundModal: #3a3a3a8c;
$backgroundTitlesRoadmap: #0000004d;
$inputColor: #e6e6e6;
$shadow: #ccc;
$disabledButton: #b0b0b0;
