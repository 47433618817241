@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .footerInfoContainer {
    display: flex;
    align-self: center;
    gap: 27rem;
    border-top: 0.2rem solid rgb(170, 167, 167);

    .listContainer,
    .socialMediaListContainer {
      min-width: 10rem;

      .title {
        margin: 5.5rem 0rem 3.3rem;
        color: $secondaryTextColor;
        font-weight: 900;
        font-size: 1.2rem;
      }
      .titleJoinUs {
        margin: 5.5rem 0rem 3rem;
        color: $secondaryTextColor;
        font-weight: 900;
        font-size: 1.2rem;
      }
      h3 {
        position: absolute;
        font-size: 1rem;
        font-weight: 100;
        color: $primarySubTextColor;
        margin: 3rem 0 0;
      }

      .socialMediaList {
        li {
          .discordImage {
            margin-left: 0.4rem;
          }
          .twitterImage {
            margin-left: 0.1rem;
          }
          .openSeaImage {
            margin-left: 1rem;
          }
          margin-left: -3.5rem;
        }
      }

      .list,
      .socialMediaList {
        padding: 0;
        height: 15rem;

        .blockedLink {
          cursor: not-allowed;
        }

        li {
          list-style: none;
          font-size: 1rem;
          font-weight: 100;
          color: $primarySubTextColor;
          padding-bottom: 1rem;
          align-items: center;
          display: flex;
          gap: 1rem;
          cursor: pointer;

          a {
            color: $primarySubTextColor;
            text-decoration: none;
          }

          .resourcesItem {
            color: $primarySubTextColor;
          }
        }

        img {
          width: 2.5rem;
        }
      }
    }

    .poweredByContainer {
      margin-left: -3rem;
      margin-top: 3rem;
      .title {
        position: absolute;
        margin: 2.5rem 0 0 0;
        min-width: 8rem;
        color: $secondaryTextColor;
        font-weight: 900;
        font-size: 1.2rem;
      }

      .emenioLogo {
        img {
          margin-top: 1rem;
          width: 14rem;
        }
      }

      img {
        margin-top: 5.5rem;
        width: 20rem;
      }
    }
  }

  .footerWeb {
    width: 118rem;
    align-self: center;
  }
}

@media (max-width: 1750px) {
  .footerContainer {
    .footerInfoContainer {
      gap: 18rem;
    }

    .footerWeb {
      width: 97rem;
    }
  }
}

@media (max-width: 1460px) {
  .footerContainer {
    .footerInfoContainer {
      gap: 18rem;
      margin-left: 1rem;
      margin-right: 1rem;

      .listContainer,
      .socialMediaListContainer {
        .title {
          margin: 5.5rem 0 1.8rem;
        }
        .titleJoinUs {
          margin: 5.5rem 0 0rem;
        }
        h3 {
          margin: 1rem 0 0;
        }
      }

      .poweredByContainer {
        .title {
          font-size: 1.2rem;
        }

        .emenioLogo {
          img {
            margin-top: 1rem;
            width: 10rem;
          }
        }

        img {
          margin-top: 5rem;
          width: 13rem;
        }
      }
    }

    .footerWeb {
      width: 100rem;
    }
  }
}

@media (max-width: 1366px) {
  .footerContainer {
    .footerInfoContainer {
      gap: 14rem;

      .listContainer,
      .socialMediaListContainer {
        .title {
          margin: 3.5rem 0 1.8rem;
        }
        .titleJoinUs {
          margin: 3.5rem 0 0;
        }
      }
      .poweredByContainer {
        margin-top: 0;
        .title {
          margin: 3.5rem 0rem 0rem;
        }

        img {
          margin-top: 6rem;
          width: 15rem;
        }
      }
    }
  }
}

@media (max-width: 1235px) {
  .footerContainer {
    .footerWeb {
      width: 90rem;
    }
  }
}

@media (max-width: 920px) {
  .footerContainer {
    .footerInfoContainer {
      gap: 9rem;
    }

    .footerWeb {
      width: 65rem;
    }
  }
}

@media (max-width: 760px) {
  .footerContainer {
    .footerInfoContainer {
      display: flex;
      flex-wrap: wrap;
      min-width: 30rem;
      margin: 0;
      position: initial;
      gap: 0;
      flex-direction: column;
      border-top: 0.15rem solid $primarySubTextColor;
      text-align: center;
      .poweredByContainer {
        display: flex;
        order: -1;
        flex-direction: column;
        margin: 3rem 0 1rem;

        .title {
          position: initial;
          margin: 0;
          text-align: center;
        }

        img {
          margin: 0rem;
          width: 17rem;
          align-self: center;
        }
      }

      .listContainer {
        order: -1;
        height: 17rem;
        align-items: center;

        h3 {
          position: relative;
          width: 20rem;
          margin: 12.5rem 0rem 0 0rem;
        }
      }

      .socialMediaListContainer {
        height: 8rem;
        justify-content: center;
        display: flex;
        .titleJoinUs,
        p {
          display: none;
        }
      }

      .listContainer,
      .socialMediaListContainer {
        align-self: center;

        .title {
          margin: 2.1rem;
          text-align: center;
        }

        .titleJoinUs {
          margin: 2.1rem;
          text-align: center;
        }

        .socialMediaList {
          display: flex;
          justify-content: space-around;
          margin-top: 5rem;
          margin-left: 0;
          height: 3.4rem;
          width: 5rem;
          gap: 0.5rem;
          li {
            margin-left: 0;
          }
          .openSeaImage {
            margin-left: 0 !important;
          }
        }

        .list,
        .socialMediaList {
          li {
            justify-content: center;
          }
        }
      }
    }

    .footerWeb {
      width: 50rem;
    }
  }
}

@media (max-width: 540px) {
  .footerContainer {
    .footerInfoContainer {
      min-width: 20rem;
      .listContainer {
        h3 {
          margin: 13rem 0 0;
        }
      }
    }

    .footerWeb {
      width: 40rem;
    }
  }
}

@media (max-width: 440px) {
  .footerContainer {
    .footerWeb {
      width: 33rem;
    }
  }
}
