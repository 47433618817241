@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";


.content { 
  p {
    font-weight: 900;
    font-style: normal;
    line-height: 2.1rem;
    font-size: 1.5rem;
    text-align: center;
    color: $secondaryTextColor;
  }

  a {
    text-decoration: none;
  }

  .url {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $linkColor;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 8rem;
  }

  .contentButton {
    text-align: center;
    margin: 3rem;
  
    img {
      height: 1.5rem;
      margin: 0 2rem 1rem;
    }
  }
}



@media (max-width: 1366px) {
  .content {
    p {
      margin-top: 0.5rem;
    }

    .url {
      margin: 0 2rem;
    }

    .contentButton {
      margin: 3.5rem 0 0;
    } 
  }
}

@media (max-width: 800px) {
  .content {
    padding: 2rem 0;

    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }

    .url {
      font-size: 0.9rem;
      line-height: 1.1rem;
      margin: 0;
    }

    .contentButton {
      margin: 7rem 0 0;
  
      img {
        height: 0.8rem;
        margin: 0 0.5rem 1rem;
      }
    }

  }
}
