@import "../../css/colors.scss";
@import "../../css/fonts.scss";
@import "../../css/flexbox.scss";

@media (max-width: 1366px) {
  .backdrop {
    .closeButtonContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 1rem;
      right: 1rem;
      .closeButtonMobile {
        display: none;
        align-self: center;
      }
      .closeButtonIpad {
        align-self: center;
      }
    }

    .menuContainer {
      display: flex;
      flex-direction: column;
      padding-top: 15vh;
      padding-bottom: 5vh;
      gap: 2rem;
      align-items: center;

      a {
        text-decoration: none;
      }

      .item {
        font-family: $raregotchiFont;
        font-size: 2.3rem;
        color: $primaryColor;
        -webkit-text-stroke: 0.073rem $primaryBackgroundColor;
        margin-block: 0;
        line-height: 4rem;
        text-shadow: 0.167rem 0.083rem 0.083rem $backgroundTitlesRoadmap;
      }

      .itemSelected {
        font-family: $raregotchiFont;
        font-size: 2.3rem;
        color: $secondaryFontColor;
        -webkit-text-stroke: 0.073rem $primaryBackgroundColor;
        margin-block: 0;
        line-height: 4rem;
        text-shadow: 0.167rem 0.083rem 0.083rem $backgroundTitlesRoadmap;
      }

      input {
        background: transparent;
        width: 20rem;
        height: 100%;
        margin-top: -0.6rem;
        margin-left: 0rem;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 540px) {
  .backdrop {
    .closeButtonContainer {
      .closeButtonMobile {
        display: initial;
      }
      .closeButtonIpad {
        display: none;
      }
    }
  }
}
