@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.myCollectionContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;

  .myCollectionTitle {
    height: 5.6rem;
    padding: 4rem 0 0;
  }

  .filters {
    display: flex;
    flex-direction: row;
    padding-top: 3rem;
    padding-bottom: 1.5rem;

    .filterNameActive,
    .filterName {
      cursor: pointer;
    }

    .filterNameActive {
      color: $secondaryFontColor !important;
    }

    .filterSeparator {
      color: $secondaryFontColor !important;
      margin: 0 3rem;
    }

    .filterNameActive,
    .filterSeparator,
    .filterName {
      font-size: 3rem;
      color: $primarySubTextColor;
    }
  }

  .toySectionContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    .text {
      font-size: 1.8rem;
      color: $primarySubTextColor;
      text-align: center;
      margin-top: 6rem;
      margin-bottom: 2.5rem;
    }
    .collectionEmpty {
      text-align: center;
      margin: 6rem 0;
      color: $primarySubTextColor;
      font-size: 1.8rem;
      line-height: 2.3rem;
      font-weight: 450;
      .lineBreak {
        display: none;
      }
    }
    .toySection {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 120rem;
    }
    .contentButton {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 4rem;
    }
  }
}

@media (max-width: 1500px) {
  .myCollectionContainer {
    .toySectionContainer {
      .toySection {
        width: 112.5rem;
      }
    }
  }
}

@media (max-width: 1366px) {
  .myCollectionContainer {
    .myCollectionTitle {
      height: 4rem;
      padding: 1.5rem 0 3rem;
    }

    .filters {
      padding: 0 0 1rem;
      .filterNameActive,
      .filterSeparator,
      .filterName {
        font-size: 2rem;
      }
    }

    .toySectionContainer {
      width: 93rem;
      align-items: center;
      .toySection {
        width: 93rem;
      }
      .text {
        margin-bottom: 3rem;
      }
      .collectionEmpty {
        max-width: 37rem;
        margin: 0 0 6rem;

        .lineBreak {
          display: block;
        }
      }
      .contentButton {
        margin-top: 0 !important;
        grid-gap: 1rem;
      }
    }
  }
}

@media (max-width: 1150px) {
  .myCollectionContainer {
    .toySectionContainer {
      width: 91rem;
      .toySection {
        justify-content: center;
        gap: 2rem;
        width: 89rem;
      }
    }
  }
}

@media (max-width: 950px) {
  .myCollectionContainer {
    .toySectionContainer {
      width: 70rem;
      .toySection {
        width: 70rem;
      }
    }
  }
}

@media (max-width: 850px) {
  .myCollectionContainer {
    .myCollectionTitle {
      padding-bottom: 0;
    }
    .filters {
      padding: 2.7rem 0rem 0rem;
    }
    .toySectionContainer {
      padding-top: 1rem !important;
      width: 67rem;

      .toySection {
        width: 67rem;
        gap: 1rem;
      }
      padding: 3rem 0;
      .text {
        margin: 3rem 0 0;
        font-size: 1.5rem;
      }
      .collectionEmpty {
        font-size: 1.5rem;
        line-height: 1.8rem;
        max-width: 30rem;
        margin: 3rem 0;
        font-weight: 450;
      }
      .contentButton {
        display: block;
      }
    }
  }
}

@media (max-width: 750px) {
  .myCollectionContainer {
    .myCollectionTitle {
      height: 3.5rem;
    }
    .toySectionContainer {
      width: 55rem;
      .toySection {
        width: 55rem;
      }
    }
    .filters {
      .filterNameActive,
      .filterSeparator,
      .filterName {
        font-size: 1.7rem;
      }
    }
  }
}

@media (max-width: 540px) {
  .myCollectionContainer {
    .myCollectionTitle {
      height: 3rem;
    }
    .filters {
      .filterNameActive,
      .filterSeparator,
      .filterName {
        padding-top: 1.3rem;
      }
    }
    .toySectionContainer {
      width: 40rem;
      padding-top: 0;
      .toySection {
        width: 40rem;
      }
      .text {
        font-size: 1.1rem;
      }
      .toySection {
        row-gap: 2rem;
      }
    }
  }
}

@media (max-width: 450px) {
  .myCollectionContainer {
    .myCollectionTitle {
      height: 2.1rem;
    }
    .toySectionContainer {
      width: 40rem;
      .toySection {
        width: 40rem;
      }
      .text {
        font-size: 1.1rem;
      }
      .toySection {
        row-gap: 2rem;
      }
    }
  }
}

@media (max-width: 340px) {
  .myCollectionContainer {
    .toySectionContainer {
      .toySection {
        width: 25rem;
      }
    }
  }
}
