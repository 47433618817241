@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";

.text {
  font-weight: 450;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.1rem;
  text-align: center;
  color: $secondaryTextColor;
  margin-block-start: 0;
  margin-block-end: 0;
}

.contentButton {
  text-align: center;
  margin: 2rem 0 1.5rem;

  img {
    height: 1.5rem;
    margin: 0 2rem 1rem;
  }
}

@media (max-width: 1366px) {
  .text {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .text {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .contentButton {
    margin: 1.5rem 0;

    img {
      height: 0.8rem;   
      margin: 0 0.5rem 1rem;
    }
  }
}
