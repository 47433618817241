@import "../../../../../css/colors.scss";
@import "../../../../../css/fonts.scss";

.content {
  text-align: center;

  p {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $secondaryTextColor;
    margin-bottom: 0;
  }

  span {
    color: $secondaryFontColor;
  }

  .contentButton {
    text-align: center;
    margin: 2rem 0 3rem;

    img {
      height: 1.7rem;
      margin: 0 8rem;
    }

    input {
      height: 1.7rem;
      margin: 0 8rem;
      background: transparent;
      width: auto;
      border-radius: 0;
    }
  }
}

@media (max-width: 1366px) {
  .content {
    p {
      margin-top: 0.6rem;
    }
    .contentButton {
      margin-bottom: 2.3rem;
      img {
        margin: 0 2rem;
      }

      input {
        margin: 0 2rem;
      }
    }
  }
}

@media (max-width: 700px) {
  .content {
    p {
      font-size: 1.35rem;
      line-height: 1.2rem;
      margin: 0.5rem 2rem;
    }

    .contentButton {
      img {
        height: 1.5rem;
        margin: 0 2rem;
      }

      input {
        height: 1.5rem;
        margin: 0 2rem;
      }
    }
  }
}

@media (max-width: 540px) {
  .content {
    p {
      font-size: 0.9rem;
      line-height: 1.2rem;
      margin: 0.5rem 2rem;
    }

    .contentButton {
      margin-bottom: 1.5rem;
      img {
        height: 1rem;
        margin: 0 2rem;
      }

      input {
        height: 1rem;
        margin: 0 2rem;
      }
    }
  }
}
