@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentButton {
  position: relative;
  text-align: center;
  cursor: pointer;

  .backgroundButtonWeb {
    width: 25rem !important;
    position: relative;
  }

  .backgroundButtonIpad {
    display: none;
  }

  .content {
    position: absolute;
    top: 0;
    margin-top: 0.2rem;
    width: 100%;

    p {
      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $raregotchiFont;
      font-size: 2.5rem;
      color: $primaryColor;
      -webkit-text-stroke: 0.083rem $primaryBackgroundColor;
      margin-block: 0;
      font-weight: 500;
    }
  }
}

@media (max-width: 1366px) {
  .contentButton {
    margin-top: 1rem;

    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
    }

    .content {
      padding: 0;
      margin-top: 0.2rem;
      p {
        height: 4rem;
        font-size: 1.8rem;
      }
    }
  }
}

@media (max-width: 820px) {
  .contentButton {
    .backgroundButtonIpad {
      width: 18rem;
    }
    .content {
      margin-top: 0.15rem;
      p {
        -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
        font-size: 1.7rem;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 620px) {
  .contentButton {
    margin-top: 0rem;
    .backgroundButtonIpad {
      width: 17rem;
      height: 4.2rem;
    }
    .content {
      margin-top: 0.1rem;
      p {
        font-size: 1.7rem;
      }
    }
  }
}
