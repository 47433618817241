.header {
  width: 120%;
  max-width: 147rem;
  margin-top: -40rem;
  height: auto;
}

.headerMobile {
  display: none;
}

@media (max-width: 1366px) {
  .header {
    width: 145%;
  }
}

@media (max-width: 1140px) {
  .header {
    margin-top: -25rem;
  }
}

@media (max-width: 800px) {
  .header {
    display: none;
  }

  .headerMobile {
    display: initial;
    width: 150%;
    margin-top: -14rem;
  }
}

@media (max-width: 600px) {
  .headerMobile {
    margin-top: -5rem;
  }
}

@media (max-width: 415px) {
  .headerMobile {
    margin-top: 0rem;
  }
}
