@import "../../css/colors.scss";
@import "../../css/fonts.scss";
@import "../../css/flexbox.scss";

.privacyPolicy {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .titlePrivacyPolicy {
    height: 5.6rem;
    margin: 3rem;
  }

  li {
    list-style-type: none;
  }

  a {
    display: initial;
  }

  .column {
    display: flex;
    gap: 1rem;
  }

  .contentText {
    padding: 2rem 15rem;

    p {
      font-size: 2.1rem;
      line-height: 2.7rem;
      text-align: left;
      font-weight: 450;
      color: $primarySubTextColor;
    }

    .onlyText {
      margin-block: 0;
    }
  }
}

@media (max-width: 1366px) {
  .privacyPolicy {
    .titlePrivacyPolicy {
      height: 5rem;
    }

    .contentText {
      padding: 2rem 5rem;

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .privacyPolicy {
    .titlePrivacyPolicy {
      margin: 1rem 3rem;
      height: 2.5rem;
    }
  }
}

@media (max-width: 540px) {
  .privacyPolicy {
    .titlePrivacyPolicy {
      height: 2rem;
    }

    .contentText {
      padding: 2rem 2rem;
      max-width: 26rem;
      p {
        font-size: 1.1rem;
        line-height: 2.1rem;
      }
    }
  }
}
