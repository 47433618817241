.curtain {
  margin-top: -330px;
  position: absolute;
  width: 100%;
  height: 1400px;
  animation-name: growBackground;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 1001;
  background-repeat: repeat-x;
  background-image: url("./../../assets/images/toyOpening/toy-opening.gif"),
    linear-gradient(#ed165a, #ed165a 75%, #00000000 75.1%, #00000000 100%);
  background-position: bottom;
}

.bottom {
  height: 100%;
  width: 100%;
}

@keyframes growBackground {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(-80%);
  }
}

@media (max-width: 1366px) {
  .curtain {
    margin-top: 0px;
  }
}

@media (max-width: 540px) {
  .curtain {
    margin-top: -300px;
  }
}
