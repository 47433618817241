@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .titleRoadmap {
    margin: 0 0 7rem;
    height: 5.6rem;
  }

  .content {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(21.8rem, 10fr));
    grid-auto-rows: minmax(auto, 150rem);

    .contentImage {
      position: relative;
      left: -8rem;

      .imageWorm {
        width: 14rem;
        height: 126rem;
        background-image: url("../../assets/images/roadmap/roadmap-background-web.png");
        background-repeat: no-repeat;
        padding-bottom: 5rem;

        %guyHeight {
          height: 10rem;
          left: 5rem;
        }
        .guyFirst {
          @extend %guyHeight;
          position: relative;
          top: -4rem;
        }
        .guySecond {
          @extend %guyHeight;
          position: relative;
          top: 1.5rem;
        }
        .guyThird {
          @extend %guyHeight;
          position: relative;
          top: 19.5rem;
        }
        .guyFourth {
          @extend %guyHeight;
          position: relative;
          top: 47rem;
        }
        .guyFifth {
          @extend %guyHeight;
          position: relative;
          top: 77.5rem;
        }
      }
    }

    .contentText {
      width: 60rem;
      position: relative;
      left: 3rem;

      %titleResponsivePosition {
        left: 0;
      }
      .title {
        font-family: $raregotchiFont;
        font-size: 3.2rem;
        color: $secondaryFontColor;
        -webkit-text-stroke: 0.073rem $primaryBackgroundColor;
        margin-block: 0;
        line-height: 4rem;
        text-shadow: 0.167rem 0.083rem 0.083rem $backgroundTitlesRoadmap;
        margin: 0 0 2rem;
      }
      .titleResponsive {
        display: none;
      }
      .text {
        font-weight: 450;
        font-size: 1.6rem;
        font-style: normal;
        line-height: 2.05rem;
        color: $primarySubTextColor;
      }
    }
  }
}

@media (max-width: 1366px) {
  .roadmap {
    .titleRoadmap {
      height: 6rem;
    }

    .content {
      .contentImage {
        left: 1rem;
        .imageWorm {
          left: -3rem;
          height: 171rem;
          width: 20rem;
          background-image: url("../../assets/images/roadmap/roadmap-background-ipad.png");

          %guyHeight {
            height: 12rem;
            left: 7rem;
          }
          .guyFirst {
            top: -3rem;
          }
          .guySecond {
            top: 8rem;
          }
          .guyThird {
            top: 32rem;
          }
          .guyFourth {
            top: 69rem;
          }
          .guyFifth {
            top: 103rem;
          }
        }
      }

      .contentText {
        left: 3.5rem;
        %titleResponsivePosition {
          left: 10rem;
        }
        .title {
          display: none;
        }
        .titleResponsive {
          font-family: $raregotchiFont;
          font-size: 4rem;
          color: $secondaryFontColor;
          margin-block: 0;
          position: relative;
          line-height: 4rem;
          display: block;
          margin-inline-start: 5rem;
          text-shadow: 0.167rem 0.083rem 0.083rem $backgroundTitlesRoadmap;
          -webkit-text-stroke: 0.073rem $primaryBackgroundColor;
        }
        .text {
          margin-inline-end: 8rem;
          margin-inline-start: 5rem;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .roadmap {
    .content {
      .contentImage {
        left: 5rem;
        .imageWorm {
          width: 20rem;
          background-image: none;
          %guyHeight {
            height: 12rem;
            left: 5rem;
          }
          .guyFirst {
            top: -4rem;
          }
          .guySecond {
            top: 6.5rem;
          }
          .guyThird {
            top: 33.5rem;
          }
          .guyFourth {
            top: 71rem;
          }
          .guyFifth {
            top: 108rem;
          }
        }
      }

      .contentText {
        left: 3.5rem;
        %titleResponsivePosition {
          left: 10rem;
        }
        .titleResponsive {
          margin-inline-start: 4.2rem;

          font-size: 3rem;
          line-height: 3.5rem;
        }
        .text {
          margin-inline-end: 15rem;
          margin-inline-start: 4.2rem;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .roadmap {
    .titleRoadmap {
      width: 25rem;
      margin-bottom: 3rem;
    }
    .content {
      .contentImage {
        left: 9.5rem;
        .imageWorm {
          width: 20rem;
          background-image: none;
          %guyHeight {
            height: 9rem;
            left: 8rem;
          }
          .guyFirst {
            top: -2rem;
          }
          .guySecond {
            top: 10.75rem;
          }
          .guyThird {
            top: 39.5rem;
          }
          .guyFourth {
            top: 80rem;
          }
          .guyFifth {
            top: 121.5rem;
          }
        }
      }

      .contentText {
        left: 3.5rem;
        %titleResponsivePosition {
          left: 10rem;
        }
        .titleResponsive {
          margin-inline-start: 5.7rem;

          font-size: 2.5rem;
          line-height: 3.5rem;
        }
        .text {
          font-size: 1.3rem;
          margin-inline-end: 23rem;
          margin-inline-start: 5.7rem;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .roadmap {
    .titleRoadmap {
      height: 3rem;
      margin-bottom: 2rem;
    }

    .content {
      grid-template-columns: repeat(auto-fit, minmax(10.8rem, 10fr));
      grid-auto-rows: minmax(auto, 50rem);

      .contentImage {
        left: 6rem;

        .imageWorm {
          width: 7rem;
          height: auto;
          background-image: none;

          %guyHeight {
            height: 7rem;
            left: 0;
          }
          .guySecond {
            top: 15rem;
          }
          .guyThird {
            top: 50.8rem;
          }
          .guyFourth {
            top: 102rem;
          }
          .guyFifth {
            top: 152.7rem;
          }
        }
      }

      .contentText {
        width: 40rem;

        %titleResponsivePosition {
          left: 8.5rem;
        }
        .title {
          display: none;
        }
        .titleResponsive {
          display: block;
          font-size: 2.3rem;
          line-height: 2.5rem;
          margin-inline-start: 4rem;
          -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
        }
        .text {
          margin-inline-start: 4rem;
          margin-inline-end: 16rem;
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .roadmap {
    .titleRoadmap {
      width: 15rem;
      margin-bottom: 2rem;
    }
    .content {
      grid-template-columns: repeat(auto-fit, minmax(10.8rem, 10fr));
      grid-auto-rows: minmax(auto, 50rem);

      .contentImage {
        left: 3.5rem;

        .imageWorm {
          width: 5rem;

          %guyHeight {
            height: 5rem;
          }
          .guyFirst {
            top: -1rem;
          }
          .guySecond {
            top: 12.25rem;
          }
          .guyThird {
            top: 42.25rem;
          }
          .guyFourth {
            top: 85.25rem;
          }
          .guyFifth {
            top: 129.65rem;
          }
        }
      }

      .contentText {
        width: 30rem;

        %titleResponsivePosition {
          left: 5rem;
        }
        .titleResponsive {
          font-size: 1.8rem;
          -webkit-text-stroke: 0.017rem $primaryBackgroundColor;
          margin-inline-start: 1.6rem;
          line-height: 2rem;
        }
        .text {
          font-size: 1.1rem;
          line-height: 1.55rem;
          margin-inline-end: 10.5rem;
          margin-inline-start: 1.6rem;
        }
      }
    }
  }
}
