@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.title {
  height: 5.6rem;
  margin: 10.2rem 0 2rem;
}

.teamMembers {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  gap: 2rem;
}

.presentation {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
  text-align: center;
  background: $primaryColor;
}

.contentImage img {
  height: 16rem;
  padding: 1.5rem 0;
}

.name {
  font-size: 1.7rem;
  line-height: 2.1rem;
  margin: 0;
  font-weight: 900;
  font-style: normal;
  color: $secondaryTextColor;
}

.description {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1rem;
}

@media (max-width: 1750px) {
  .title {
    height: 5rem;
  }

  .contentImage img {
    height: 13.9rem;
  }
}

@media (max-width: 1250px) {
  .title {
    margin: 5rem 0rem 3.4rem;
  }

  .teamMembers {
    margin-top: 0;
  }

  .contentImage img {
    height: 8.9rem;
  }

  .name {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1.2rem;
  }
}

@media (max-width: 710px) {
  .title {
    width: 38rem;
  }

  .contentImage img {
    height: 7.5rem;
  }
}

@media (max-width: 540px) {
  .teamMembers {
    flex-direction: column;
  }

  .title {
    height: 2.5rem;
    margin: 2rem 0rem 1rem;
  }

  .contentImage img {
    height: 13.9rem;
  }
}
