@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.eventTitle {
  margin: 5rem 0 1.6rem;
  height: 5.6rem;
}

.contentText {
  text-align: center;
  padding: 0 5rem 1.6rem;
  max-width: 50rem;

  p {
    line-height: 2.3rem;
    font-size: 1.8rem;
    text-align: center;
    color: $primarySubTextColor;
  }

  .text {
    margin-block-start: 0.5em;
    margin-block-end: 0;
  }
}

@media (max-width: 1366px) {
  .eventTitle {
    height: 5rem;
  }

  .contentText {
    max-width: 44rem;

    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

@media (max-width: 680px) {
  .eventTitle {
    width: 43rem;
  }

  .contentText {
    width: 33rem;

    p {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 540px) {
  .eventTitle {
    height: 2.5rem;
    margin: 5rem 0 1rem;
  }

  .contentText {
    padding: 0 5rem 1rem;

    p {
      margin: 1.5rem auto;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.3rem;
      max-width: 22.5rem;
    }
  }
}
