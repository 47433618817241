@import "../../../../../css/colors.scss";
@import "../../../../../css/fonts.scss";

.content {
  text-align: center;
  padding: 0 0.5rem;

  p {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $secondaryTextColor;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  .url {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $linkColor;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 8rem;
  }

  .contentButton {
    text-align: center;
    margin: 2rem 0;

    img {
      height: 1rem;
      margin: 0 8rem;
    }
  }
}

@media (max-width: 1366px) {
  .content {
    p {
      margin-top: 0.5rem;
    }

    .url {
      margin: 0 2rem;
    }
  }
}

@media (max-width: 800px) {
  .content {
    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }

    .url {
      font-size: 0.9rem;
      line-height: 1.1rem;
      margin: 0;
    }

    .contentButton {
      img {
        margin: 0;
      }
    }
  }
}
