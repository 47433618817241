@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentButton {
  position: relative;
  text-align: center;
  cursor: not-allowed;

  .backgroundButtonWeb {
    width: 39.1rem;
    position: relative;
  }

  .backgroundButtonIpad {
    display: none;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 1.5rem;
    cursor: not-allowed;

    p {
      width: 100%;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $raregotchiFont;
      font-size: 2rem;
      color: $primaryTextColor;
      -webkit-text-stroke: 0.083rem $primaryBackgroundColor;
      margin-block: 0;
      cursor: not-allowed;
    }
  }
}

@media (max-width: 1366px) {
  .contentButton {
    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
    }

    .content {
      top: 1rem;
      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .contentButton {
    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
      max-width: 16rem;
    }

    .content {
      top: 0;
      p {
        -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
        font-size: 1.1rem;
        height: 4rem;
      }
    }
  }
}
