@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.backButton {
  text-decoration: none;
  border: none;
  color: $navigationLinkColor;
  background-color: $primaryColor;
  font-size: 1.1rem;
  font-weight: 900;
  cursor: pointer;
  margin-left: 0.3rem;
}

@media (max-width: 540px) {
  .backButton {
    font-size: 1.3rem;
  }
}

@media (max-width: 400px) {
  .backButton {
    font-size: 1.1rem;
  }
}
