@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.roadmap {
  background: $secondBackgroundColor;
  text-align: center;

  width: 100%;
  padding: 4.3rem 0 1.5rem;

  .image {
    width: 37.5rem;
  }
}

@media (max-width: 1750px) {
  .roadmap {
    .image {
      width: 30.5rem;
    }
  }
}

@media (max-width: 1250px) {
  .roadmap {
    .image {
      width: 20.5rem;
    }
  }
}
