@import "../../../../../css/colors.scss";
@import "../../../../../css/fonts.scss";

.content {
  text-align: center;

  p {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $secondaryTextColor;
    margin-top: 0 !important;
    margin-bottom: 3rem;
  }
}

@media (max-width: 800px) {
  .content {
    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
      margin-bottom: 2rem;
    }
  }
}
