@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentButton {
  position: relative;
  text-align: center;
  cursor: pointer;

  .backgroundButtonWeb {
    width: 39.1rem;
    position: relative;
  }

  .backgroundButtonIpad {
    display: none;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 1.5rem;
  }

  .contentDisabled {
    position: absolute;
    width: 100%;
    top: 1.5rem;
    cursor: not-allowed;
  }

  p {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $raregotchiFont;
    font-size: 2rem;
    color: $primaryColor;
    -webkit-text-stroke: 0.083rem $primaryBackgroundColor;
    margin-block: 0;
  }
}

@media (max-width: 1366px) {
  .contentButton {
    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
    }

    .content {
      top: 1rem;
    }

    .contentDisabled {
      top: 1rem;
      cursor: not-allowed;
    }

    p {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 800px) {
  .contentButton {
    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
      max-width: 16rem;
    }

    .content {
      top: 0;
    }

    .contentDisabled {
      top: 0;
      cursor: not-allowed;
    }
    
    p {
      -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
      height: 4rem;
      font-size: 1.1rem;
    }
  }
}
