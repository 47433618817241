@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentButton {
  position: relative;
  text-align: center;
  cursor: pointer;

  .backgroundButtonWeb {
    width: 39.1rem;
    position: relative;
    height: 6rem;
    padding-bottom: 0.5rem;
  }

  .backgroundButtonIpad {
    display: none;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 1.5rem;

    p {
      width: 100%;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $raregotchiFont;
      font-size: 2.5rem;
      color: $primaryColor;
      -webkit-text-stroke: 0.083rem $primaryBackgroundColor;
      margin-block: 0;
    }
  }
}

@media (max-width: 1366px) {
  .contentButton {
    margin-top: 1rem;
    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
    }

    .content {
      top: 0;
      p {
        font-size: 1.8rem;
        height: 6.2rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .contentButton {
    .content {
      p {
        -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
        font-size: 1.7rem;
      }
    }
  }
}
