@import "../../../css/colors.scss";

.petCardsContainer {
  position: relative;
  display: flex;
  gap: 3rem;
  justify-content: center;
  width: 100%;

  padding-bottom: 3rem;
  background: linear-gradient(180deg, #fff 45%, $secondBackgroundColor 0);

  &.reverseBackground {
    background: linear-gradient(180deg, $secondBackgroundColor 60%, #fff 0);
  }
}

@media (max-width: 1250px) {
  .petCardsContainer {
    background: linear-gradient(180deg, #fff 40%, $secondBackgroundColor 0);

    &.reverseBackground {
      background: linear-gradient(180deg, $secondBackgroundColor 70%, #fff 0);
    }
  }
}

@media (max-width: 800px) {
  .petCardsContainer {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;

    background: linear-gradient(180deg, #fff 12%, $secondBackgroundColor 0);

    &.reverseBackground {
      background: linear-gradient(180deg, $secondBackgroundColor 13%, #fff 0);
    }
  }
}
