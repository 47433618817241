@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.overlay {
  position: fixed;
  inset: 0;
  background-color: $backgroundModal;
  backdrop-filter: blur(1.3rem);
  z-index: 9999;
}

.overlayTransactionInProgress {
  position: fixed;
  inset: 0;
  z-index: 9999;
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 55rem;
  height: auto;
}

.modalContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 1.5rem;
  background-color: $primaryColor;
  border-color: $primaryColor;
  height: 24rem;
  outline: none;

  img {
    cursor: pointer;
  }
}

.modalContentTransactionInProgress {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 1.5rem;
  height: 24rem;
  outline: none;

  img {
    cursor: pointer;
  }
}

.contentCross {
  text-align: right;

  img {
    height: 4rem;
  }
}

.content {
  text-align: center;
  padding: 0 0.5rem;
}

@media (max-width: 1366px) {
  .modal {
    width: 40rem;
  }

  .modalContent {
    height: 22rem;
  }
}

@media (max-width: 800px) {
  .content {
    padding: 0 2rem;
  }

  .modal {
    width: 22rem;
  }

  .contentCross {
    img {
      height: 2.5rem;
    }
  }
}
