@import "../../css/colors.scss";
@import "../../css/fonts.scss";
@import "../../css/flexbox.scss";

.navbar {
  @include flexCenter(center);
  position: absolute;
  font-family: $primaryFont;
  background-color: $primaryColor;
  z-index: 1000;
  width: 100%;
  height: 5.4rem !important;
  box-shadow: 0px 0px 10px $shadow;
  color: $secondaryTextColor;
  top: 1.5rem;
  gap: 4rem;

  .logoContainer {
    width: 25rem;
    display: flex;

    .navigationLogo {
      width: 18rem;
    }
    .navigationLogoResponsive {
      display: none;
    }

    .menuIconMobile {
      display: none;
    }

    .menuIconIpad {
      display: none;
    }
  }

  .navOptions {
    display: flex;

    ul {
      min-width: 52rem;
      display: flex;
      justify-content: flex-end;

      li {
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 600;
        list-style: none;
        text-decoration: none;
        margin: 0 1.1rem;
        color: $navigationLinkColor;
        align-self: center;

        .itemLinkActive {
          text-decoration: none;
          padding: 2rem 0rem 1.35rem;
          margin: 0 1.5rem;
          color: $secondaryFontColor;
          border-bottom: 0.5rem solid $secondaryFontColor;
        }

        .itemLink {
          text-decoration: none;
          margin: 0 1.5rem;
          padding-top: 0.1rem;
          color: $secondaryFontColor;
        }

        a {
          text-decoration: none;
          color: $secondaryFontColor;
        }

        input,
        img {
          width: 14rem;
          height: 3rem;
          margin-top: 0.6rem;
          margin-left: 0rem;
          cursor: pointer;
        }
      }
    }

    .imgContainer {
      align-self: center;
      min-width: 10rem;
    }
  }
}

.stickyNav {
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 2050px) {
  .navbar {
    height: 7.99rem;
  }
}

@media (max-width: 1850px) {
  .navbar {
    img {
      margin-left: 3rem;
    }
  }
}

@media (min-width: 1450px) {
  .navbar {
    height: 7.95rem;
  }
}

@media (max-width: 1366px) {
  .navbar {
    height: 7.4rem;
    img {
      margin: 0;
    }
    .navOptions {
      display: none;
    }
    .logoContainer {
      width: 100%;
      margin: 0 3rem;
      justify-content: space-between;
      .menuIconIpad {
        display: initial;
        color: $secondaryFontColor;
        align-self: center;
        justify-self: flex-end;
      }
      .menuIconMobile {
        display: none;
      }
      .navigationLogo {
        display: none;
      }
      .navigationLogoResponsive {
        display: initial;
        width: 15rem;
        margin-left: -1.5rem;
      }
    }
  }
}

@media (max-width: 540px) {
  .navbar {
    height: 3.4rem;
    .navOptions {
      display: none;
    }

    .logoContainer {
      margin: 0 1.4rem;

      .menuIconMobile {
        display: initial;
        color: $secondaryFontColor;
        align-self: center;
        height: 2rem;
      }

      .menuIconIpad {
        display: none;
      }

      .navigationLogo {
        display: none;
      }
      .navigationLogoResponsive {
        display: initial;
        margin: 0;
      }
    }
  }
}
