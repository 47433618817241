@import "../../../css/colors.scss";

.meetThePets {
  width: 100%;
  text-align: center;
  position: relative;
  background-color: $secondBackgroundColor;
}

.stain {
  position: absolute;
  top: 42rem;
  left: -23rem;
  z-index: 0;
  width: 31.9rem;
}

.titleContainer {
  background-color: #ffffff;
}

.title {
  margin: 3rem 0;
  width: 57rem;
}

.bottomText {
  position: relative;
  z-index: 10;
  text-align: center;
  color: $primarySubTextColor;
  width: 100%;

  br {
    display: none;
  }

  p {
    margin: 0 auto 0 auto;
    padding: 2rem 3rem 5rem 3rem;
    max-width: 64rem;
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}

@media (max-width: 1366px) {
  .bottomText {
    p {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }
  }
}

@media (max-width: 1250px) {
  .stain {
    top: 27rem;
  }

  .title {
    max-width: 38rem;
    width: 95%;
  }
}

@media (max-width: 710px) {
  .stain {
    top: 21rem;
  }

  .bottomText {
    p {
      font-size: 1.5rem;
    }
    br {
      display: block;
    }
  }
}

@media (max-width: 540px) {
  .bottomText {
    line-height: initial;
    font-size: 1.1rem;

    p {
      font-size: 1.1rem;
      line-height: 1.4rem;
      padding: 0 1rem 4rem 1rem;
    }
  }
}
