@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .titleMint {
    height: 5.6rem;
  }

  .subtitleMint {
    margin: 5rem 0 3rem 0;
    height: 2.8rem;
  }

  .contentText {
    text-align: center;
    max-width: 71rem;
    .text {
      font-size: 1.8rem;
      line-height: 2.3rem;
      font-style: normal;
      font-weight: 450;
      font-family: $secondaryFont;
      color: $primarySubTextColor;
      text-align: center;
    }
    .interlineIpad {
      display: none;
    }
    .interlineMobile {
      display: none;
    }
  }

  .contentCard {
    box-shadow: 0 0 2rem 0.1rem $effectBlurColor;
    border-radius: 3.1rem;
    transform: scale(1.03);
    margin: 3rem 10rem 10rem 10rem;
    padding: 2rem 0;

    .contentGif {
      display: none;
    }

    .toys {
      display: flex;
      justify-content: center;
      .image {
        width: 17rem;
        height: 31.68rem;
      }
      .card {
        padding: 1rem 4rem;
        justify-content: center;
        text-align: center;
        .name {
          font-size: 2.1rem;
          line-height: 2.7rem;
          font-style: normal;
          font-weight: 900;
          font-family: $secondaryFont;
          color: $secondaryTextColor;
        }
      }
    }
  }

  .contentMint {
    display: flex;
    justify-content: center;
    align-items: center;

    .contentAmount {
      background-color: $secondBackgroundColor;
      border-radius: 3rem;
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0 0 0 5rem;
      .minus {
        margin: 0 1rem 0 1rem;
        cursor: pointer;
        width: 3rem;
      }
      .minusDisabled {
        margin: 0 1rem 0 1rem;
        cursor: not-allowed;
        width: 3rem;
      }
      .amount {
        width: 3rem;
      }
      .plus {
        margin: 0 1rem 0 1rem;
        cursor: pointer;
        width: 3rem;
      }
      .plusDisabled {
        margin: 0 1rem 0 1rem;
        cursor: not-allowed;
        width: 3rem;
      }
      .amount {
        margin: 1.2rem 2.5rem;
        min-width: 2.5rem;
        font-size: 1.9rem;
        line-height: 2.4rem;
        font-style: normal;
        font-family: $secondaryFont;
        font-weight: 900;
        color: $thirdTextColor;
      }
    }

    .contentPrice {
      margin: 1.5rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .priceUnit {
        font-family: $secondaryFont;
        font-size: 1.7rem;
        font-weight: 900;
        font-style: normal;
        line-height: 2.2rem;
        color: $secondaryTextColor;
        margin: 0 3rem;
      }
      .priceTotal {
        font-family: $secondaryFont;
        font-size: 2.5rem;
        font-weight: 900;
        font-style: normal;
        line-height: 3.2px;
        color: $fourthTextColor;
        margin: 0 3rem;
        min-width: 12rem;
      }
    }
  }

  .contentButton {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      background: transparent;
      height: auto;
    }

    .mintButton {
      cursor: pointer;
    }

    .mintButtonDisabled {
      cursor: not-allowed;
    }
  }
}

@media (max-width: 1366px) {
  .mint {
    .titleMint {
      height: 5rem;
    }

    .subtitleMint {
      margin: 2rem 0 0 0;
      height: 2.5rem;
    }

    .contentText {
      max-width: 45rem;
    }

    .contentCard {
      margin: 0.5rem 5rem 3.5rem;
      padding: 1rem 0;
      max-width: 55rem;
      .toys {
        .image {
          width: 9.7rem;
          height: 17rem;
        }
        .card {
          padding: 1rem 3.5rem;
          .name {
            font-size: 1.1rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .contentMint {
      .contentAmount {
        margin: 0 0 0 2.5rem;

        .minus {
          margin: 0 1rem;
        }
        .minusDisabled {
          margin: 0 1rem;
        }
        .plus {
          margin: 0 1rem;
        }
        .plusDisabled {
          margin: 0 1rem;
        }
        .amount {
          margin: 0.5rem 0.8rem;
          min-width: 2rem;
          font-size: 1.6rem;
          line-height: 2rem;
        }
        .amountLine {
          max-height: 2rem;
        }
      }

      .contentPrice {
        margin: 0 1rem;
        .priceUnit {
          margin: 0 1rem;
          font-size: 1.3rem;
          line-height: 1.6rem;
        }
        .priceLine {
          height: 4rem;
        }
        .priceTotal {
          margin: 0 1rem;
          min-width: 7rem;
          font-size: 1.3rem;
          line-height: 1.6rem;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .mint {
    .titleMint {
      width: 46rem;
    }

    .subtitleMint {
      width: 32rem;
    }

    .contentText {
      width: 36rem;
      margin-bottom: 1rem;
      .text {
        font-size: 1.5rem;
      }
    }
    .contentCard {
      margin: 0.5rem 5rem 3.5rem;
      padding: 1rem 0;
      max-width: 55rem;
      .toys {
        width: 38rem;
        .image {
          width: 7.5rem;
          height: 13rem;
        }
        .card {
          padding: 1.8rem;
          .name {
            font-size: 1rem;
          }
        }
      }
    }
    .contentMint {
      width: 33rem;
      margin: 0 auto;
      .contentAmount {
        margin: 0rem 0 0 1.5rem;
        width: 8.6rem;
        .minus,
        .minusDisabled {
          margin: 0 0.5rem;
        }
        .plus,
        .plusDisabled {
          margin: 0 0.5rem;
        }
        .amountLine {
          max-height: 1.7rem;
        }
        .amount {
          font-size: 1.5rem;
          margin: 0.5rem 0.2rem;
        }
      }

      .contentPrice {
        .priceUnit {
          font-size: 1.2rem;
        }
        .priceLine {
          font-size: 1.2rem;
        }
        .priceTotal {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .mint {
    .titleMint {
      height: 2.5rem;
    }

    .subtitleMint {
      margin: 1.5rem 0 0.5rem;
      height: 1.5rem;
    }

    .contentText {
      max-width: 19rem;
      .text {
        font-size: 1.1rem;
        line-height: 1.4rem;
      }
    }

    .contentCard {
      margin: 0.5rem 5rem 2rem;
      width: 29rem;
      padding-top: 1rem;
      text-align: center;
      .toys {
        display: none;
      }

      .contentGif {
        display: initial;
        .gif {
          max-width: 12rem;
        }
      }
    }

    .contentMint {
      width: 19rem;
      .contentAmount {
        width: 11.5rem;
        height: 2.4rem;
        margin: 0;
        .minus {
          max-width: 2rem;
          margin: 0 0.6rem;
        }
        .minusDisabled {
          max-width: 2rem;
          margin: 0 0.6rem;
        }
        .plus {
          max-width: 2rem;
          margin: 0 0.6rem;
        }
        .plusDisabled {
          max-width: 2rem;
          margin: 0 0.6rem;
        }
        .amountLine {
          max-height: 2rem;
        }
        .amount {
          margin: 0 0.8rem;
          font-size: 2rem;
          line-height: 2rem;
        }
      }

      .contentPrice {
        .priceUnit {
          font-size: 1rem;
          line-height: 1rem;
          margin: 0 0.5rem;
          min-width: 4.5rem;
        }
        .priceLine {
          max-height: 2.9rem;
        }
        .priceTotal {
          font-size: 1rem;
          line-height: 1rem;
          margin: 0 0.1rem;
          min-width: 4.5rem;
        }
      }
    }
  }
}
