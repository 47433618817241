@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .error404 {
    max-width: 36rem;
    margin-top: 14rem;
  }

  .text {
    font-size: 5.6rem;
    line-height: 7rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    color: $secondaryTextColor;
    margin-block-end: 0;
    margin-block-start: 2rem;
  }

  .subtext {
    font-size: 1.8rem;
    line-height: 2.3rem;
    text-align: center;
    color: $primarySubTextColor;
    margin-bottom: 15rem;
  }
}

@media (max-width: 1366px) {
  .error {
    .error404 {
      max-width: 29rem;
      margin-top: 7rem;
    }

    .text {
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin-block-start: 1rem;
    }

    .subtext {
      font-size: 1.4rem;
      line-height: 1.1rem;
      margin-block-end: 8rem;
      margin-bottom: 7rem;
    }
  }
}

@media (max-width: 800px) {
  .error {
    .error404 {
      max-width: 17rem;
      margin-top: 2rem;
    }

    .text {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }

    .subtext {
      font-size: 0.9rem;
      margin-inline-start: 4rem;
      margin-inline-end: 4rem;
      margin-bottom: 5rem;
    }
  }
}
