@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.contentButton {
  position: relative;
  text-align: center;
  cursor: pointer;

  .backgroundButtonWeb {
    width: 25rem !important;
    position: relative;
  }

  .backgroundButtonIpad {
    display: none;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 0;

    p {
      width: 100%;
      height: 5.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $raregotchiFont;
      font-size: 2.5rem;
      color: $primaryTextColor;
      -webkit-text-stroke: 0.083rem $primaryBackgroundColor;
      margin-block: 0;
      font-weight: 500;
      cursor: not-allowed;
    }
  }
}

@media (max-width: 1366px) {
  .contentButton {
    margin-top: 1rem;

    .backgroundButtonWeb {
      display: none;
    }

    .backgroundButtonIpad {
      display: initial;
    }

    .content {
      padding: 0;

      p {
        height: 4.5rem;
        font-size: 2rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .contentButton {
    .content {
      padding: 0.2rem 0 0 0;

      p {
        -webkit-text-stroke: 0.042rem $primaryBackgroundColor;
        font-size: 1.7rem;
        height: 4.2rem;
      }
    }
  }
}
