@import "../../../css/colors.scss";

.imageMeetTheToys {
  max-width: 50%;
  margin-top: 5rem;
}

.imageMeetTheToysMobile {
  display: none;
  margin-top: 5rem;
  max-width: 50%;
}

.contentText {
  text-align: center;
  padding: 1.8rem 5rem;
  z-index: 5;
  max-width: 100rem;
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $primarySubTextColor;
    text-align: center;
  }
}

.contentSubscribe {
  text-align: center;
}

@media (max-width: 1750px) {
  .imageMeetTheToys {
    max-width: 50%;
  }
}

@media (max-width: 1366px) {
  .imageMeetTheToys {
    max-width: 70%;
  }

  .contentText {
    .text {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }
  }
}

@media (max-width: 540px) {
  .imageMeetTheToys {
    display: none;
  }

  .imageMeetTheToysMobile {
    display: block;
    max-width: 90%;
  }

  .contentText {
    padding: 1.8rem 2rem;

    .text {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
  }
}
