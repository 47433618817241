@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

.collapsibleContainer {
  display: flex;
  height: 5rem;
  padding: 0 1rem 0 2rem;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: $primaryColor;
  box-shadow: 0.3rem 0.3rem 2.2rem 0 rgba(147, 156, 178, 0.2);
  align-items: center;
  .questions {
    margin: auto 0;
    font-size: 1.7rem;
    color: $secondaryTextColor;
    font-weight: 900;
  }

  .toggleAnswers {
    background-color: $primaryColor;
    border: none;
    border-radius: 50%;
    margin: auto 0;
    img {
      cursor: pointer;
      width: 3.2rem;
      height: 3.1rem;
      background-color: $secondBackgroundColor;
      border-radius: 50%;
    }
  }

  .toggleAnswersResponsive {
    display: none;
  }
}

.collapsibleContainerExtended {
  display: grid;
  grid-template-columns: 95% 5%;
  padding: 0 1rem 0 2rem;
  border-radius: 1rem;
  background-color: $primaryColor;
  box-shadow: 0.3rem 0.3rem 2.2rem 0rem rgba(147, 156, 178, 0.2);
  .questions {
    width: 90%;
    margin: 1.15rem 0;
    font-size: 1.7rem;
    color: $secondaryTextColor;
    font-weight: 900;
  }

  p {
    color: $primarySubTextColor;
    font-size: 1.55rem;
  }

  .toggleAnswers {
    background-color: $primaryColor;
    border: none;
    justify-self: flex-end;
    height: 5rem;
    width: 5rem;
    text-align: center;
    margin: auto 0;
    img {
      cursor: pointer;
      width: 3.2rem;
      height: 3.1rem;
      background-color: $secondBackgroundColor;
      border-radius: 50%;
    }
  }

  .toggleAnswersResponsive {
    display: none;
  }
}

@media (max-width: 1366px) {
  .collapsibleContainer {
    padding-right: 0.5rem;
    .questions {
      font-size: 1.45rem;
    }
    .toggleAnswers {
      display: none;
    }
    .toggleAnswersResponsive {
      display: initial;
      background-color: $primaryColor;
      border: none;
      height: 2rem;
      margin: auto 0;
      padding-right: 0;
      align-self: center;
      img {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        padding: 0.3rem;
        background-color: $secondBackgroundColor;
        border-radius: 50%;
      }
    }
  }
  .collapsibleContainerExtended {
    .questions {
      margin-top: 1.5rem;
      font-size: 1.45rem;
    }
    p {
      font-size: 1.35rem;
    }
    .toggleAnswers {
      display: none;
    }
    .toggleAnswersResponsive {
      display: initial;
      background-color: $primaryColor;
      border: none;
      height: 3rem;
      margin: auto 0;
      padding-left: 0.5rem;
      width: 2.3rem;
      justify-self: flex-end;

      img {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0.2rem 0 0;
        padding: 0.3rem;
        background-color: $secondBackgroundColor;
        border-radius: 50%;
      }
    }
  }
}

@media (max-width: 540px) {
  .collapsibleContainer {
    padding-right: 0.4rem;
    height: 3.5rem;
    .questions {
      font-size: 1.05rem;
    }

    .toggleAnswersResponsive {
      height: 2rem;
      width: 2.3rem;
      align-self: initial;

      img {
        background-color: $secondBackgroundColor;
        padding: 0.3rem;
        height: 0.85rem;
        width: 0.85rem;
      }
    }
  }

  .collapsibleContainerExtended {
    padding-right: 0.2rem;
    .questions {
      font-size: 1.05rem;
      height: 2rem;
      margin-top: 1rem;
    }
    p {
      font-size: 0.95rem;
    }
    .toggleAnswersResponsive {
      height: 2rem;
      width: 2.3rem;
      margin-top: 0.5rem;
      padding-left: 0.38rem;

      img {
        background-color: $secondBackgroundColor;
        padding: 0.3rem;
        height: 0.85rem;
        width: 0.85rem;
      }
    }
  }
}

@media (max-width: 465px) {
  .collapsibleContainer {
    .questions {
      font-size: 0.95rem;
    }
  }
}

@media (max-width: 360px) {
  .collapsibleContainer {
    .questions {
      font-size: 0.85rem;
    }
  }

  .collapsibleContainerExtended {
    .questions {
      font-size: 0.85rem;
    }
  }
  p {
    font-size: 0.65rem;
  }
}
