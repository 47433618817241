@import "../../../../css/colors.scss";
@import "../../../../css/fonts.scss";

.text {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 1000;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: $secondaryTextColor;
  margin-block-start: 0;
}


.name {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: $secondaryFontColor;
  margin-block: 1.5em;
}

.contentButton {
  text-align: center;
  margin: 3rem 0 2.5rem;

  img {
    height: 1.5rem;
    margin: 0 2rem 1rem;
  }

  input {
    height: 1.5rem;
    margin: 0 2rem 1rem;
    background: transparent;
    width: auto;
    border-radius: 0;
    padding: 0;
  }
}

@media(max-width: 1366px) {
  .contentButton {
    margin: 2rem 0 2.5rem;

    input {
      margin: 0 2rem 1rem;
    }
  }
}

@media (max-width: 800px) {
  .content {
    position: absolute;
    top: 20%;
    width: 100%;
    left: 0;
  }

  .text {
    font-size: 0.9rem;
  }

  .name {
    margin-block-end: 3.5rem;
  }

  .contentButton {
    margin: 2.5rem 0 0;

    img {
      height: 0.8rem;
      margin: 0 0.5rem 1rem;
    }

    input {
      height: 0.8rem;
      margin: 0 0.5rem 1rem;
    }
  }
}
