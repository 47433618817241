@import "../../css/colors.scss";
@import "../../css/fonts.scss";

.myCollectionOpen,
.myCollection {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .backButtonContainer {
    display: flex;
    height: 2rem;
    width: 100rem;
  }
  .arrowBack {
    margin-top: -0.15rem;
  }

  .contentCollection {
    width: 105rem;
    height: 82rem;
    .myCollectionTitle {
      width: 20.06rem;
      margin: 2rem 0rem 5rem 0rem;
    }

    .openImageContainerMobile,
    .sealedImageContainerMobile {
      display: none;
    }
    .toyInfoContainer {
      display: flex;
      grid-template-columns: repeat(2, 10fr);
      grid-auto-rows: minmax(auto, auto);
      align-items: flex-start;
      justify-content: center;
      gap: 4rem;
      margin: 2rem 0 15.2rem;

      .contentDescription {
        height: 50rem;
        max-width: 50rem;
        .backButtonContainer {
          display: flex;
          height: 2rem;
          margin: 2rem 0rem 1.5rem;
        }
        .arrowBack {
          color: $navigationLinkColor;
          padding-top: 0.2rem;
        }

        .title {
          margin: 0;
          font-weight: 900;
          font-style: normal;
          font-size: 2rem;
          line-height: 2.5rem;
          color: $secondaryTextColor;
          text-transform: uppercase;
        }

        .collection {
          width: 20.06rem;
          margin-bottom: 5rem;
        }

        .tokenId {
          font-weight: 500;
          font-style: normal;
          font-size: 2rem;
          line-height: 2.1rem;
          margin-block-start: 0.5rem !important;
          color: $secondaryFontColor;
        }

        .productDescriptionContainer {
          width: 50rem;
          .productDescription {
            font-size: 2rem;
            font-weight: 400, regular;
            color: $primarySubTextColor;
          }
        }
        .contentButton {
          position: relative;
          left: -0.5rem;
          margin-top: 3.2rem;
          display: flex;
          flex-wrap: wrap;
          .buttonOpensea {
            display: flex;
            p {
              font-size: 2.4rem;
            }
          }
        }
      }
    }
    .sealedImageContainer {
      display: flex;
      justify-content: flex-end;
      .sealedImage {
        cursor: pointer;
        width: 50rem;
      }
    }
    .openImageContainer {
      .openImage {
        cursor: pointer;
        width: 50rem;
      }
    }
  }
}

.myCollectionOpen {
  .contentCollection {
    height: 70rem;
  }
}

@media (max-width: 1420px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      .toyInfoContainer {
        .contentDescription {
          width: 70rem;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      height: 53rem;
      width: 85rem;

      .myCollectionTitle {
        width: 17rem;
        margin: 0rem 0rem 2rem 0rem;
      }
      .toyInfoContainer {
        gap: 1.16rem;
        margin-bottom: 6rem;
        height: 40rem;

        .contentDescription {
          width: 40rem;
          .productDescriptionContainer {
            width: 40rem;
            .productDescription {
              margin-top: 0.5rem;
              margin-bottom: 0rem;
              font-size: 1.3rem;
              line-height: 2rem;
            }
          }
          .collection {
            width: 17rem;
            margin-bottom: 4.3rem;
          }
          .tokenId {
            font-size: 1.3rem;
            line-height: 1.4rem;
            font-weight: 600;
            margin-bottom: 0.3rem;
          }
          .title {
            font-size: 1.65rem;
            line-height: 1.8rem;
          }
          .contentButton {
            left: -0.2rem;
            margin-top: 2rem;
            .buttonOpensea {
              p {
                font-size: 1.8rem;
              }
            }
          }
        }
        .sealedImageContainer {
          .sealedImage {
            width: 30rem;
          }
        }
        .openImageContainer {
          .openImage {
            width: 30rem;
          }
        }
      }
    }
  }
  .myCollectionOpen {
    .contentCollection {
      height: 45rem;
    }
  }
}

@media (max-width: 1200px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      width: 72rem;
      margin-bottom: -2rem;
    }
  }
}

@media (max-width: 950px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      height: 53rem;
      width: 63rem;
      .toyInfoContainer {
        height: 45rem;
        .contentDescription {
          width: 33rem;
          .collection {
            width: 15rem;
            margin-bottom: 3.5rem;
          }

          .title {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
          }

          .tokenId {
            font-size: 1.1rem;
            margin: 0;
          }

          .productDescriptionContainer {
            width: 30rem;
            .productDescription {
              margin-top: 0.5rem;
              font-size: 1.19rem;
            }
          }
        }
        .sealedImageContainer {
          .sealedImage {
            width: 27rem;
          }
        }
        .openImageContainer {
          .openImage {
            width: 27rem;
          }
        }
      }
    }
  }

  .myCollectionOpen {
    .contentCollection {
      height: 47rem;
    }
  }
}

@media (max-width: 800px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      justify-content: center;
      display: block;
      width: 35rem;
      text-align: center;
      padding: 0;
      margin: 0;
      height: 100rem;

      .toyInfoContainer {
        display: initial;

        .contentDescription {
          width: 100%;
          padding: 0 0 3rem 0;

          .sealedImageContainerMobile {
            display: initial;
            .sealedImageMobile {
              width: 30rem;
            }
          }
          .openImageContainerMobile {
            display: initial;
            .openImageMobile {
              width: 30rem;
            }
          }

          .myCollectionTitle {
            margin: 0.5rem 0rem 3.5rem 0rem;
            width: 23rem;
          }
          .backButtonContainer {
            display: flex;
            height: 2rem;
            margin-bottom: 1.5rem;
            margin-left: 3.5rem;
            .arrowBack {
              padding-top: 0.3rem;
            }
          }
          .arrowBack {
            padding-top: 0.2rem;
          }
          .title {
            font-size: 1.7rem;
            line-height: 1.6rem;
            margin: 2.5rem 0rem 1.2rem 0rem;
          }

          .collection {
            width: 20rem;
          }

          .tokenId {
            font-size: 1.4rem;
            line-height: 1.5rem;
            margin-bottom: 1.2rem;
          }

          .productDescriptionContainer {
            margin: 0 auto;
            width: 30rem;
            .productDescription {
              width: 30rem;
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 1.9rem;
            }
          }
          .contentButton {
            flex-direction: column;
            left: 0;
            margin-top: 2rem;
            justify-content: center;

            .buttonOpensea {
              align-self: center;

              .viewOpensea {
                height: 6rem;
              }
            }

            .button3d {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
        }
        .sealedImageContainer,
        .openImageContainer {
          display: none;
        }
      }
    }
  }

  .myCollectionOpen {
    .contentCollection {
      height: 90rem;
    }
  }
}

@media (max-width: 620px) {
  .myCollection {
    .contentCollection {
      height: 97rem;
    }
  }

  .myCollectionOpen {
    .contentCollection {
      height: 88rem;
    }
  }
}

@media (max-width: 450px) {
  .myCollection {
    .contentCollection {
      justify-content: center;
      display: block;
      text-align: center;
      padding: 0;
      width: 25rem;
      height: 93rem;
      .toyInfoContainer {
        .contentDescription {
          align-items: center;
          padding: 0 0rem 2rem 0;

          .sealedImageContainerMobile {
            .sealedImageMobile {
              width: 25rem;
            }
          }
          .openImageContainerMobile {
            .openImageMobile {
              width: 25rem;
            }
          }

          .backButtonContainer {
            display: flex;
            height: 1.7rem;
            margin-bottom: 1.8rem;
            margin-left: 0rem;
          }
          .arrowBack {
            padding-top: 0.2rem;
          }

          .myCollectionTitle {
            width: 21rem;
          }

          .title {
            font-size: 1.5rem;
            line-height: 1.6rem;
            margin: 2.5rem 0rem 0.9rem 0rem;
          }

          .collection {
            width: 18rem;
          }

          .tokenId {
            font-size: 1.2rem;
            line-height: 1.5rem;
            margin-bottom: 1.3rem;
          }
          .productDescriptionContainer {
            width: 25rem;
            .productDescription {
              width: 25rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .myCollectionOpen,
  .myCollection {
    .contentCollection {
      height: 90rem;
      .toyInfoContainer {
        .contentDescription {
          width: 100%;

          .sealedImageContainerMobile {
            .sealedImageMobile {
              width: 23rem;
            }
          }
          .openImageContainerMobile {
            .openImageMobile {
              width: 23rem;
            }
          }
          .backButtonContainer {
            .arrowBack {
              padding-top: 0.2rem;
            }
          }
          .collection {
            width: 15rem;
          }

          .myCollectionTitle {
            width: 20rem;
          }
          .title {
            font-size: 1.5rem;
          }
          .tokenId {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .myCollectionOpen {
    .contentCollection {
      height: 79rem;
    }
  }
}
