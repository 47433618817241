@import "../../../css/colors.scss";
@import "../../../css/fonts.scss";

a {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.packagesContainerPet,
.packagesContainerOpen,
.packagesContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem 2rem;
  margin: 0;
  border-radius: 3rem;
  box-shadow: 0 3rem 2.2rem -1rem rgba(147, 156, 178, 0.2);
  align-items: center;

  .toyImage {
    width: 20rem;
  }
  .toyName {
    text-align: center;
    font-weight: 900;
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 1.05rem;
    text-transform: uppercase;
    color: $secondaryTextColor;
  }
  .toyId {
    font-weight: 900;
    font-size: 1.05rem;
    color: $secondaryFontColor;
    margin-top: 0;
    margin-bottom: 0;
  }
  .viewButton {
    font-family: $raregotchiFont;
    font-size: 3.2rem;
    color: $secondaryFontColor;
    margin: 1.2rem 0 0;
    -webkit-text-stroke: 0.067rem $primaryBackgroundColor;
    line-height: 4rem;
    text-shadow: 0.167rem 0.083rem 0.083rem $backgroundTitlesRoadmap;
  }
}
.packagesContainerOpen {
  padding-left: 1.334rem;
  padding-right: 1.334rem;
  .toyImage {
    width: 23.334rem;
  }
}
.packagesContainerPet {
  padding: 4rem 1.5rem 2rem !important;
  .toyImage {
    width: 23rem;
    margin-top: 4.2rem;
    margin-bottom: 2.81rem;
  }
}

@media (max-width: 1500px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    margin: 0;
    padding: 4rem 2.501rem 2rem;
    .viewButton {
      margin: 0.5rem;
    }
  }
  .packagesContainerOpen {
    padding-left: 0.834rem !important;
    padding-right: 0.834rem !important;
  }
  .packagesContainerPet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .toyImage {
      margin-top: 3.5rem;
      margin-bottom: 3.51rem;
    }
  }
}

@media (max-width: 1366px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 3rem 2.5rem 1.7rem;
    margin: 1rem 1.7rem;
    .toyName {
      font-size: 1.2rem;
      margin-top: 1.6rem;
    }
  }
  .packagesContainerOpen {
    padding-left: 0.834rem !important;
    padding-right: 0.834rem !important;
    .toyImage {
      width: 23.334rem;
    }
  }
  .packagesContainerPet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .toyImage {
      width: 23rem;
      margin-top: 3.25rem;
      margin-bottom: 2.75rem;
    }
  }
}

@media (max-width: 1150px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 2.4rem 2rem 1.4rem;
    .toyImage {
      width: 16.8rem;
    }
    .toyName {
      font-size: 1rem;
      margin-top: 1.3rem;
    }
    .viewButton {
      text-align: center;
      font-size: 2.5rem;
    }
  }
  .packagesContainerOpen {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    .toyImage {
      width: 19.601rem;
      margin-bottom: 0;
    }
  }
  .packagesContainerPet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .toyImage {
      width: 19rem;
      margin-top: 1.8rem;
      margin-bottom: 2.8rem;
    }
  }
}

@media (max-width: 950px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 2rem 2.4rem 1rem;
    .toyImage {
      width: 19.2rem;
    }
  }

  .packagesContainerOpen {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    .toyImage {
      width: 22.4rem;
      margin-bottom: 0.05rem;
    }
  }
  .packagesContainerPet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .toyImage {
      width: 22rem;
      margin-top: 1.5rem;
      margin-bottom: 3.37rem;
    }
  }
}

@media (max-width: 750px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 1.1rem 2.8rem 0.2rem;
    border-radius: 2rem;
    margin: 0.5rem;
    .toyImage {
      width: 14.3rem;
    }
    .toyName {
      font-size: 1rem;
    }
    .viewButton {
      width: 5rem;
      font-size: 2rem;
    }
  }

  .packagesContainerOpen {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;

    .toyImage {
      width: 16.684rem;
    }
  }

  .packagesContainerPet {
    padding: 0.8rem !important;
    .toyImage {
      width: 18.3rem;
      margin-top: 1.9rem;
      margin-bottom: 1.6rem;
    }
  }
}

@media (max-width: 540px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 0 3rem;
    margin-top: 3rem;
    .toyImage {
      width: 20.5rem;
    }
    .toyName {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    .viewButton {
      font-size: 1.8rem;
      margin-left: 1.2rem;
    }
  }

  .packagesContainerOpen {
    padding-left: 1.292rem !important;
    padding-right: 1.292rem !important;
    .toyImage {
      width: 23.917rem;
      margin-bottom: 0;
    }
  }
  .packagesContainerPet {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    .toyImage {
      width: 24rem;
    }
    .viewButton {
      margin-top: 0.5rem;
    }
  }
}

@media (max-width: 400px) {
  .packagesContainerPet,
  .packagesContainerOpen,
  .packagesContainer {
    padding: 0 2rem;
    .toyImage {
      width: 17.8rem;
    }
  }

  .packagesContainerOpen {
    padding-left: 0.516rem !important;
    padding-right: 0.516rem !important ;
    .toyImage {
      width: 20.767rem;
    }
  }
  .packagesContainerPet {
    padding-left: 0.9rem !important;
    padding-right: 0.9rem !important ;
    .toyImage {
      width: 20rem;
    }
  }
}

@media (max-width: 340px) {
  .packagesContainer {
    .toyImage {
      width: 15rem;
    }
  }

  .packagesContainerOpen {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    .toyImage {
      width: 17.5rem;
    }
  }

  .packagesContainerPet {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    .toyImage {
      width: 18rem;
    }
  }
}
