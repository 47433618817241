@import "../../../css/colors.scss";

.mintYourPets {
  background-color: $secondBackgroundColor;
  width: 100%;
  text-align: center;

  .title {
    padding: 0 1rem;
    height: 5.6rem;
  }
}

.stain {
  position: absolute;
  right: -20rem;
  z-index: 0;
  width: 32.2rem;
}

@media (max-width: 1250px) {
  .mintYourPets {
    .title {
      height: 5rem;
    }
  }
  .stain {
    right: -23rem;
    top: -10.6rem;
  }
}

@media (max-width: 710px) {
  .mintYourPets {
    .title {
      height: 2.5rem;
    }
  }
}
